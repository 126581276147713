.rdw-editor-main {
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    border: solid 1px gainsboro;
    padding: 15px;
    margin-top: 0px;
}
.public-DraftStyleDefault-block {
    margin:  0px !important;
}
.DraftEditor-root {
    font-size: 14px;
  }

  .p-datatable.p-datatable-gridlines .p-datatable-tbody>tr>td{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .user-attended{
    background: var(--green-700);
    padding: 5px 19px;
    border-radius: 6px;
    color: #fff;
  }
  .user-notattended{
    background: var(--yellow-400);
    padding: 5px 19px;
    border-radius: 6px;
    color: #000;
  }