.header{
    background-color: #009688;
}
.p-panelmenu{

    .p-panelmenu-header > a {
        border: none !important;
        padding: 18px 10px !important;
        font-size: 14px !important;
    }
    .p-panelmenu-content{
        border: none !important;
    }

}

.page-container{
    width: calc(100% - 20px);
    margin: 10px 10px;
    height: calc(100vh - 84px);
}

.page-bg{
    background: #ededed;
    height: 100vh;
    width: 100vw;
}