.datatable-filter-demo .p-paginator .p-paginator-current {
    margin-left: auto;
}
.datatable-filter-demo .p-progressbar {
    height: 0.5rem;
    background-color: #d8dadc;
}
.datatable-filter-demo .p-progressbar .p-progressbar-value {
    background-color: #607d8b;
}
.datatable-filter-demo .p-datepicker {
    min-width: 25rem;
}
.datatable-filter-demo .p-datepicker td {
    font-weight: 400;
}
.datatable-filter-demo .p-datatable.p-datatable-customers .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
}
.datatable-filter-demo .p-datatable.p-datatable-customers .p-paginator {
    padding: 1rem;
}
.datatable-filter-demo .p-datatable.p-datatable-customers .p-datatable-thead > tr > th {
    text-align: left;
}
.datatable-filter-demo .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td {
    cursor: auto;
}
.datatable-filter-demo .p-datatable.p-datatable-customers .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
}
.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
    padding: 5px;
    font-size: 13px !important;
}
.user-full-name{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.dialog-container{
    padding: 20px;
    background: whitesmoke;
    position: relative;
    border-radius: 5px;
    .form-group{
        label {
            margin-bottom: 2px;
            font-size: 13px;
        }
        .p-inputtext {
            font-size: 13px;
            border: none;
            padding: 8px 10px;
        }
    }
}
.dialog-loader{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff8bf;
    padding: 3px 15px;
    z-index: 1;
}
.badge-forverify{

    background-color: yellow;
    padding: 5px 10px;
    border-radius: 8px;
    font-weight: bold;
}
.badge-approved{
    color: #fff;
    background-color: green;
    padding: 5px 10px;
    border-radius: 8px;
    font-weight: bold;
}
.head-label{
    float: left;
    width: 100%;
    margin: 10px 0px;
}
.search_text{
    height: 35px;
    padding: 9px !important;
    border-radius: 11px !important;
    width: 340px;
    float: right;
}

.payment_history_table{
    font-size: 13px !important;
}
.paid{
    background-color: green;
    padding: 5px 10px;
    border-radius: 5px;
    color: #fff;
    
}
.unpaid{
    background-color: yellow;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: 500;
}

.nowrap{
    white-space: nowrap;
}

.download-head{
    width: 40px !important;
    text-align: center !important;
    .fa{
        font-size: 20px;
        color: rgb(157, 29, 29);
        cursor:pointer;
    }
    .fa.disabled{
        font-size: 20px;
        color: rgb(202 202 202);
        cursor:default;
    }
}

.page-list{
    float: left;
    border-bottom: solid 1px rgb(194, 194, 194);
    padding: 10px;

    
}
.page-list.active{
    background: #c4ffc4;
}

.info-page-link{
    font-weight: bold;
    color: blue;
    // float: left;
    cursor: pointer;
    text-decoration: underline;

}